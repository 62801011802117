<template>
  <v-expand-transition>
    <v-container fluid>
      <v-row dense>
        <v-col cols="12">
          <v-container class="outlined-card" v-if="product && product.id > 0" fluid>
            <v-row dense>
              <v-col cols="12" md="6" lg="4" v-if="imageLibrary.images.length || true">
                <image-viewer
                  :images="imageLibrary.images"
                ></image-viewer>
              </v-col>
              <v-col cols="12" md="6" lg="8">
                <v-card flat
                  height="100%"
                  class="flexcard"
                >
                  <v-card-text class="text-center">
                    <h1 v-html="product.title"></h1>
                    <div class="subheading">{{product.subtitle}}</div>
                  </v-card-text>
                  <v-window v-model="window" class="grow" touchless>
                    <v-window-item :key="0">
                      <!-- Price & Action -->
                      <v-card-text class="text-center pt-0" v-if="currentSelection">
                        <h2 v-if="currentSelection.options.length > 0 && currentSelection.hasRange">{{currentSelection.priceRange[0] | usDollars}} - {{currentSelection.priceRange[1] | usDollars}}</h2>
                        <h2 v-else-if="currentSelection.displayPrice">{{currentSelection.displayPrice | usDollars}}</h2>
                        <h2 v-else-if="currentSelection.displayPrice < 0">Coming Soon</h2>
                        <v-row>
                          <v-col v-if="currentSelection.iLimit > 1" style="max-width: 75px" class="d-flex align-center">
                            <v-select
                              :items="quantityOptions"
                              v-model="quantity"
                              label="Quantity"
                              hide-details
                            ></v-select>
                          </v-col>
                          <v-col>
                            <v-btn
                              color="color3 color3Text--text"
                              class="mt-3"
                              block
                              large
                              @click.stop="actionClick"
                              :disabled="disabled"
                              style="height: 60px"
                            >
                              <h1>{{product.jsonProps.actionText || 'Add to Cart'}}</h1>
                            </v-btn>

                          </v-col>
                        </v-row>
                        <v-divider class="mt-6 mx-12"></v-divider>
                      </v-card-text>
                      <!-- Description & More Text -->
                      <v-card-text class="pt-0">
                        <div v-html="description"></div>
                        <v-expand-transition>
                          <div v-html="product.moreInfo" v-if="product.moreInfoOpen" class="pt-4"></div>
                        </v-expand-transition>
                        <div
                          v-if="product.moreInfo"
                          class="text-center"
                        >
                          <v-btn
                            color="color3"
                            text small
                            @click.stop="product.moreInfoOpen = !product.moreInfoOpen"
                          >{{moreText}}</v-btn>
                        </div>
                      </v-card-text>
                      <!-- Options -->
                      <v-slide-x-transition group mode="out-in" leave-absolute>
                        <template  v-for="s in selected">
                          <v-card-text v-if="s.options && s.options.length" :key="s.id">
                            <template>
                              <div v-if="s.jsonProps.optionsText" class="title mb-3" :class="{'color3--text': s.selected < 0}">{{ s.jsonProps.optionsText }}</div>
                              <v-slide-group
                                v-if="s.options.length > 0"
                                show-arrows
                                center-active
                                v-model="s.selected"
                                @change="onToggle(s)"
                              >
                                <v-slide-item
                                  v-for="o in s.options" :key="o.id"
                                  v-slot="{ active, toggle }"
                                >
                                  <v-btn
                                    class="mx-2"
                                    :class="{ 'text-decoration-line-through': o.iLimit === 0}"
                                    :input-value="active"
                                    active-class="color3 color3Text--text"
                                    depressed
                                    rounded
                                    @click="toggle"
                                  >
                                    {{ getOptionText(o) }}
                                  </v-btn>
                                </v-slide-item>
                              </v-slide-group>
                            </template>
                          </v-card-text>
                        </template>
                        <!-- NAME YOUR PRICE -->
                        <v-card-text v-if="currentSelection && currentSelection.price === -13" key="altAmount">
                          <div class="title">
                            Please Enter Your Amount
                          </div>
                          <v-text-field
                            label="Amount"
                            v-model="currentSelection.altAmount"
                            solo
                            type="number"
                            prefix="$"
                            @blur="formatPrice"
                          ></v-text-field>
                        </v-card-text>
                      </v-slide-x-transition>
                      <!-- SOLD OUT ALERT -->
                      <v-expand-transition>
                        <v-alert type="error" text :value="currentSelection && currentSelection.id && currentSelection.iLimit === 0">
                          Out of stock
                        </v-alert>
                      </v-expand-transition>
                      <!-- SHIPPING NAME -->
                      <v-card-text v-if="currentSelection && currentSelection.contactReq === 'name'">
                        <v-text-field
                          label="Your Name*"
                          v-model="shippingInfo.name"
                          solo-inverted
                          ref="shipName"
                        ></v-text-field>
                      </v-card-text>
                    </v-window-item>
                    <v-window-item :key="1">
                      <v-card-text>
                        <v-card color="grey lighten-3">
                          <v-card-text>
                            <div class="title">
                              This product requires a player.
                            </div>
                            <div class="subheading">
                              Please add a player by searching below.
                            </div>
                            <v-expand-transition>
                              <player-lookup
                                v-if="!manual && !searchR"
                                @player-selected="onPlayerSelected"
                              ></player-lookup>
                            </v-expand-transition>
                            <div>
                              <v-btn
                                color="color3"
                                text
                                class="xsmall pl-0 ml-0"
                                @click.stop="reset(searchR ? false : !manual)"
                              >
                                {{searchR ? 'Search Again' : manual ? 'Player Search' : 'Manual Entry'}}
                              </v-btn>
                            </div>
                            <v-expand-transition>
                              <person-form
                                v-if="manual"
                                :player="player"
                                :fields="['name', 'gender']"
                                :requiredFields="['phone', 'email', 'fullAddress']"
                                :embed="true"
                                @valid-change="(v) => {this.validPlayer = v}"
                              ></person-form>
                            </v-expand-transition>
                            <v-expand-transition>
                              <v-list two-line v-if="searchR">
                                <search-result-item
                                  :player="searchR"
                                  :showId="false"
                                ></search-result-item>
                              </v-list>
                            </v-expand-transition>
                          </v-card-text>
                        </v-card>
                      </v-card-text>
                    </v-window-item>
                    <v-window-item :key="2">
                      <v-card-text>
                        <v-card color="grey lighten-3">
                          <v-card-text>
                            <div class="title">
                              {{currentSelection.contactReq === 'contact' ? 'Contact Information' : 'Shipping Information'}}
                            </div>
                            <div class="subheading">
                              Please complete the {{currentSelection.contactReq === 'contact' ? 'contact' : 'shipping'}} information below.
                            </div>
                            <v-expand-transition>
                              <person-form
                                :player="shippingInfo"
                                :fields="[]"
                                :requiredFields="requiredFields"
                                :embed="true"
                                @valid-change="(v) => {this.validShipping = v}"
                              ></person-form>
                            </v-expand-transition>
                          </v-card-text>
                        </v-card>
                      </v-card-text>
                    </v-window-item>
                  </v-window>
                  <v-divider></v-divider>
                  <!-- BUTTONS -->
                  <v-card-actions>
                    <v-fab-transition>
                      <v-btn
                        v-if="window > 0"
                        color="color3Text color3--text"
                        fab small
                        @click.stop="window = 0"
                      >
                        <v-icon>fas fa-caret-left</v-icon>
                      </v-btn>
                    </v-fab-transition>
                    <v-spacer></v-spacer>
                    <v-col v-if="currentSelection && currentSelection.iLimit > 1" style="max-width: 75px" class="d-flex align-center">
                      <v-select
                        :items="quantityOptions"
                        v-model="quantity"
                        label="Quatity"
                        hide-details
                      ></v-select>
                    </v-col>
                    <v-fab-transition>
                      <v-btn
                        :key="`textBtn-${disabled}`"
                        color="color3"
                        text
                        @click.stop="actionClick"
                        :disabled="disabled"
                      >
                        Add To cart
                      </v-btn>
                    </v-fab-transition>
                    <v-fab-transition>
                      <v-btn
                        :key="disabled"
                        color="color3Text color3--text"
                        fab small
                        @click.stop="actionClick"
                        :disabled="disabled"
                      >
                        <v-icon>fas fa-shopping-cart</v-icon>
                      </v-btn>
                    </v-fab-transition>
                  </v-card-actions>
                </v-card>
              </v-col>
            </v-row>
            <!-- ADDED TO CART DIALOG -->
            <v-dialog v-model="cartDialog" max-width="500">
              <v-card>
                <v-card-title class="title text-center">
                  Your {{product.title}} has been added to the cart!
                </v-card-title>
                <v-card-actions class="justify-center">
                  <v-btn color="color3 color3Text--text" class="mr-2" :to="{name: 'checkout'}">Check Out Now</v-btn>
                  <v-btn color="color3 color3Text--text" @click.stop="noGoToCart">Add Another</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-container>
          <v-container v-else-if="loading" fluid>
            <v-row dense>
              <v-col cols="12" class="py-12 text-center">
                <h1>Loading...</h1>

                <v-progress-circular indeterminate color="color3" class=""></v-progress-circular>
              </v-col>
            </v-row>
          </v-container>
        </v-col>
      </v-row>
    </v-container>
  </v-expand-transition>
</template>

<script>
import { mapGetters } from 'vuex'
import Product from '@/classes/Product'
import CartItem from '@/classes/CartItem'
import * as actions from '@/store/ActionTypes'
import PlayerLookup from '@/components/Player/Search/PlayerLookup'
import PersonForm from '@/components/Forms/PersonForm'
import PlayerProfile from '@/classes/PlayerProfile'
import SearchResultItem from '@/components/Player/Search/SearchResultItem'
import max from 'lodash.max'

export default {
  props: ['tag', 'addOn', 'pickupOnly', 'linkId'],
  data () {
    return {
      product: {
        player: null,
        title: 'Loading',
        subtitle: '...',
        jsonProps: {
          imageLibrary: {
            images: ['NA']
          },
          requirements: {
            player: true
          }
        }
      },
      loading: false,
      window: 0,
      manual: false,
      validPlayer: false,
      searchR: null,
      cartDialog: false,
      selected: [],
      quantity: 1,
      shippingInfo: {
        delivery: 'none',
        name: null,
        phone: null,
        email: null,
        address: null,
        address2: null,
        city: null,
        state: null,
        zip: null
      },
      validShipping: false
    }
  },
  computed: {
    ...mapGetters(['color1IsDark', 'color2IsDark']),
    currentSelection () {
      return this.selected[this.selected.length - 1]
    },
    disabled () {
      if (!this.product.published) return true
      console.log('here')
      switch (this.window) {
        case 0:
          return !(this.validSelection && (this.currentSelection.contactReq !== 'name' || this.shippingInfo.name))
        case 1:
          return !this.validPlayer
        case 2:
          return !this.validShipping
      }
      return true
    },
    validSelection () {
      const c = this.currentSelection
      return c && c.options.length === 0 && (c.price !== -13 || c.altAmount)
    },
    moreText () {
      if (this.product.moreInfoBtn) {
        const splt = this.product.moreInfoBtn.split('|')
        return this.product.moreInfoOpen ? splt.length > 1 ? splt[1] : splt[0] : splt[0]
      }
      return this.product.moreInfoOpen ? 'Less' : 'More'
    },
    imageLibrary () {
      return this.product.jsonProps && this.product.jsonProps.imageLibrary
    },
    player () {
      return this.product.player
    },
    cartItem () {
      const item = new CartItem()
      item.newProduct(this.product)
      return item
    },
    iFrame () {
      return window.location.href.includes('iframe=true')
    },
    requiredFields () {
      if (this.currentSelection && ['contact', 'ship'].includes(this.currentSelection.contactReq)) {
        const a = ['name', 'phone', 'email']
        this.currentSelection.contactReq === 'ship' && a.push('fullAddress')
        return a
      }
      return false
    },
    quantityOptions () {
      return this.currentSelection ? [...Array(this.currentSelection.iLimit).keys()].map(x => ++x) : []
    },
    description () {
      return this.getDisplayProperty('description')
    }
  },
  methods: {
    getDisplayProperty (prop) {
      const options = [...this.selected].reverse()
      const option = options.find(opt => {
        const p = opt[prop]
        if (p) {
          if (Array.isArray(p)) {
            return p.length > 0
          }
          return true
        }
        return false
      })
      return option ? option[prop] : null
    },
    getOptionText (o) {
      return o.title || (o.price > 0 ? `$${o.price}` : 'Other')
    },
    onToggle (o) {
      const myI = this.selected.findIndex(f => f.id === o.id)
      const l = this.selected.length
      const d = l - (myI + 1)
      if (d > 0) {
        this.selected.splice(myI + 1, d)
      }

      if (o.selectedOption) {
        this.selected.push(o.selectedOption)
      }
    },
    noGoToCart () {
      this.cartDialog = false
      this.$emit('add-another')
    },
    reset (manual) {
      this.validPlayer = false
      this.searchR = null
      this.manual = manual
      if (this.currentSelection && this.currentSelection.altAmount) {
        this.currentSelection.altAmount = null
      }
      const p = this.product
      p.player = new PlayerProfile()
      p.selected = -1
      this.selected = [p]
      if (p.options.length === 1) {
        p.selected = 0
        this.selected.push(p.options[0])
      }
      this.shippingInfo = {
        delivery: this.product.delivery,
        name: null,
        phone: null,
        email: null,
        address: null,
        address2: null,
        city: null,
        state: null,
        zip: null
      }
      this.validShipping = false
    },
    getProduct () {
      this.loading = true
      this.$VBL.store.productGet(this.tag)
        .then(r => {
          this.product = new Product(r.data)
          this.reset()
        })
        .catch(e => console.log(e.response))
        .finally(() => {
          this.loading = false
        })
    },
    actionClick () {
      const c = this.currentSelection
      if (this.window === 0 && c.jsonProps.requirements.player) {
        if (!c.player) {
          c.player = new PlayerProfile()
        }
        this.window = 1
      } else if (this.window === 0 && this.requiredFields) {
        this.window = 2
      } else {
        if (!this.disabled) {
          this.addToCartClick()
        } else {
          this.showErrors()
        }
      }
    },
    showErrors () {
      console.log('show errors')
    },
    addToCartClick () {
      const item = new CartItem()
      const extra = {
        shippingInfo: this.shippingInfo,
        linkId: this.linkId
      }
      item.newProduct(this.product, extra)
      item.quantity = this.quantity
      this.$store.dispatch(actions.ADD_CART_ITEM, item)
      this.reset()
      this.window = 0
      this.$emit('added-to-cart')
      if (this.addOn) return
      if (!this.iFrame) {
        this.cartDialog = true
      } else {
        this.$router.push({ name: 'checkout' })
      }
    },
    onPlayerSelected (val) {
      this.searchR = val
      this.player.setProfile(val)
      this.validPlayer = true
    },
    formatPrice (opt) {
      const a = this.currentSelection.altAmount
      this.currentSelection.altAmount = (isNaN(a) || +a === 0) ? null : parseFloat(Math.abs(a)).toFixed(2)
    }
  },
  watch: {
    window: function (val) {
      if (val === 0) this.reset()
    },
    'quantityOptions.length': function (v) {
      if (this.quantity && this.quantity > max(this.quantityOptions)) {
        this.quantity = max(this.quantityOptions)
      }
    },
    currentSelection: function (v) {
      this.shippingInfo.delivery = v.delivery
    }
  },
  components: {
    PlayerLookup,
    PersonForm,
    SearchResultItem,
    ImageViewer: () => import('@/components/Utils/ImageLibraryViewer.vue')
  },
  mounted () {
    console.log('mounted')
    this.getProduct()
  },
  created () {
    console.log('created')
  }
}
// $emit('selected', product)
</script>

<style scoped>
.outlined-card {
  border: 2px solid var(--v-color3-base) !important
}
</style>
